import React, {useEffect} from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import {KGlackinTestimonial} from "../components/testimonial";
import SEO from "../components/seo";
import RightFeature from "../components/right-feature";
import FeaturePararaph from "../components/feature-paragraph";
import calculatorSrc from "../images/multi-device.png";
import FreeTrial from "../components/free-trial";

const DownloadOption = ({name, link}) => {
    return (
        <div className="flex cursor-pointer">
            <span className="text-lg font-bold text-highlight-600 hover:underline"><a target="_blank" href={link} rel="noreferrer noopener">{name} </a> </span>
        </div>
    )
}

const Demo = () => {
    useEffect(() => {
        const embedScript = document.createElement("script")
        const firstScript =
            document.getElementsByTagName("script")[0] ||
            document.querySelector("body")
        embedScript.async = true
        embedScript.src = "https://embed.savvycal.com/v1/embed.js"

        embedScript.onload = () => {
            try {
                window.SavvyCal("init")
            } catch {}

            // Replace `link` with your scope/slug
            window.SavvyCal("inline", {
                link: "timekeeper/demo",
                selector: "#savvycal-widget",
                hideBanner: true,
            })
        }

        firstScript.parentNode.insertBefore(embedScript, firstScript)
    }, []);
    return (
    <Layout>
        <SEO
            title="Book a Demo"
            description="Book a demo of TimeKeeper and let us show you how our time and attendance platform can save you time and money."
        />
        <Navbar />
        <div className="bg-gray-50">
            <div className="relative bg-white overflow-hidden">
                <div className="relative pt-6 pb-16 ">
                    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                                <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
                                    Book a <span className="text-highlight-600">demo</span>  <br/> of TimeKeeper
                                </h2>
                                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                    Are you interested in discovering how TimeKeeper can benefit your business?
                                </p>
                                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                    We’d love to show you around the platform and help you assess if it’s the right solution for your needs.
                                </p>
                                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                    Simply choose a date and time to connect with a member of the TimeKeeper team via video call. Once your demo is scheduled, you’ll receive a Google Meet calendar invite for the session.
                                </p>
                                <p className="mt-3 italic text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                    Please note that we prioritise demos for companies with more than 50 employees. If your company has fewer than 50 employees, feel free to submit your inquiry, and we’ll follow up with a booking confirmation. In the meantime, we recommend watching our pre-recorded demo <span className="font-bold"><a href="https://timekeeper.wistia.com/medias/y7avl7x2yg">here</a></span>.
                                </p>
                            </div>

                            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center ">
                                <div className="relative mx-auto w-full rounded-lg lg:max-w-full">
                                    <div id="savvycal-widget" style={{ width: '100%'}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
            <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
                <RightFeature
                    sectionTitle="Whistlestop tour"
                    title="Quick Resources"
                    body={
                        <div>
                            <FeaturePararaph>
                                Pressed for time and can’t attend a live demo? No worries—we’ve got you covered.
                            </FeaturePararaph>
                            <FeaturePararaph>
                                Here are some quick resources: a TimeKeeper brochure you can easily share with your colleagues, or if you prefer, watch a pre-recorded demo that gives you a complete walkthrough at your convenience.
                            </FeaturePararaph>
                            <div className="flex flex-row justify-between items-center mt-4">
                                <DownloadOption name="TimeKeeper Brochure" link="https://timekeeper-assets.s3.eu-west-1.amazonaws.com/guides/TimeKeeper_Brochure_October_2023.pdf" />
                                <DownloadOption name="Watch a pre-recorded demo" link="https://timekeeper.wistia.com/medias/y7avl7x2yg" />

                            </div>

                        </div>
                    }
                    imgSrc={calculatorSrc}
                    imgAlt="Weekly Timesheet Template Calculator"
                />
            </div>
        </div>
        <KGlackinTestimonial />
        <FreeTrial includeDemo={false}  />
        <Footer/>
    </Layout>
)}

export default Demo;
